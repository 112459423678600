<script setup>

import {computed, onBeforeMount, onMounted, onUnmounted, ref} from "vue";
import {useSessionStore} from "@/utils/useSessionStore.js";
import ChennelTalk from "@/components/ui/ChennelTalk.vue";
import AppFooter from "@/AppFooter.vue";
import AppHeader from "@/AppHeader.vue";
import AppFooterDesktop from "@/AppFooterDesktop.vue";
import AppHeaderDesktop from "@/AppHeaderDesktop.vue";
import {useScreenHelper} from "@/plugins/ScreenHelper.js";
import {__, getLanguagePackage} from "@/i18n/useLanguage.js";
import {useRoute, useRouter} from "vue-router";
import {usePopup} from "@/utils/usePopup.js";
import ModalDialog from "@/components/form/ModalDialog.vue";
import {ArrowLeft,MessageCircle,Truck,RefreshCw,Search} from "lucide-vue-next";

const sessionStore = useSessionStore();
const openSideBar = ref(false);
const loading = ref(false);
const Popup = usePopup();
function moveToLink() {
  Popup.readPopup(Popup.getModel?.id);
  if(Popup.getModel?.link){
    if(sessionStore.isKorean){
      window.location.href = Popup.getModel.link;
    }else{
      window.location.href = Popup.getModel.link_en;
    }

  }
}

async function initPopup() {
  await Popup.getActivePopup();
  hasActivePopup.value = Popup.hasUnread();
}

onBeforeMount(() => {
  sessionStore.init();
});

const route = useRoute();

const blankTemplate = computed(() => {
  // route.path 가 /admin 으로 시작
  return route.meta?.blank || route.path.startsWith('/admin');
})

const screenHelper = useScreenHelper();

onBeforeMount(async () => {
  await getLanguagePackage()
  loading.value = true;
});

onMounted(() => {
  screenHelper.registerResizeEvent()
  initPopup();
});

onUnmounted(() => {
  screenHelper.unregisterResizeEvent()
});
const hasActivePopup = ref(false);
const channelTalk =ref(null);
const router = useRouter();

const refreshPage = ()=>{
  window.location.reload();
}
const isShowFootBar = ref(true);
const showFootBar = ()=>{
  isShowFootBar.value = true;
}
const hideFootBar = ()=>{
  isShowFootBar.value = false;
}
</script>

<template>
  <modal-dialog
    v-if="Popup.hasUnread()"
    v-model:visible="hasActivePopup"
    :cancel-label="$t('닫기')"
    :confirm-label="$t('자세히 보기')"
    class="no-padding-inner bg-transparent-inner"
    @confirm="moveToLink"
    @cancel="()=>{ Popup.readPopup(Popup.getModel?.id); hasActivePopup = false }"
  >
    <img
      class="cursor-pointer"
      :src="__(Popup.getModel?.thumbnail_ko, Popup.getModel?.thumbnail_en)"
      @click="moveToLink"
    >
  </modal-dialog>
  <div v-if="blankTemplate">
    <router-view />
  </div>
  <div
    v-if="!blankTemplate"
    class="min-h-screen h-full mb-16 lg:mb-0"
  >
    <app-header-desktop v-if="screenHelper.isDesktop" />
    <app-header
      v-else
      v-model:side-bar="openSideBar"
    />
    <router-view v-slot="{Component}">
      <keep-alive :max="3">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <app-footer-desktop v-if="screenHelper.isDesktop" />
    <app-footer v-else />
  </div>
  <footer
    :class="{ 'front-top' : isShowFootBar }"
    class="lg:hidden fixed h-16 bottom-0 left-0 p-1 w-full z-10 flex items-center justify-center border-t border-stone-300 bg-stone-100"
  >
    <ul class="flex justify-evenly w-full">
      <li><ArrowLeft @click="()=>{ router.back(); }" /></li>
      <li><refresh-cw @click="()=>{ refreshPage() }" /></li>
      <li><truck @click="()=>{ router.push({ name: 'MyPageOrder' }) }" /></li>
      <li>
        <Search @click="()=>{ router.push({name:'ProductSearch'}) }" />
      </li>
      <li><MessageCircle @click.stop="()=>{channelTalk.openTalk();}" /></li>
    </ul>
  </footer>
  <chennel-talk
    ref="channelTalk"
    @hide="showFootBar"
    @show="hideFootBar"
  />
</template>

<style scoped>
.menu-icons img {
  cursor: pointer;
}
.front-top{
  z-index: 20000000;
}
</style>

